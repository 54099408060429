/* Generic */

* {
  box-sizing: border-box;
  font-family: var(--primary-font-family), sans-serif;
  color: var(--text-color);
}

html,
body {
  height: 100%;
}

body {
  font-size: var(--default-font-size);
}

::selection {
  background: var(--color-primary);
  /* WebKit/Blink Browsers */
}

::-moz-selection {
  background: var(--color-primary);
  /* Gecko Browsers */
}
