/* Buttons */

button {
    display: grid;
    place-items: center;
    padding: 0;
    border: none;
    outline: none;
    background: none;
    cursor: pointer;
}
