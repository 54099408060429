  /* Forms */
  
  input,
  textarea,
  select,
  label {
    font-size: var(--default-font-size);
  }

  input.ng-dirty.ng-invalid {
    border-color: var(--color-red);
  }

  //HACK: This fixes the issue of date input collapsing height until it gains focus on mobile.
  input[type="date"] {
    @media (max-width: $bp-md) {
      &.ng-pristine {
        &:before {
          content: 'Please select a date';
          display: block;
          margin-right: var(--spacing-small);
        }
      }
    }
  }

  select {
    background-color: var(--color-white);
  }

  fieldset {
    border: none;
  }

   ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: var(--color-light-grey);
    /*font-weight: 300;*/
  }

   ::-moz-placeholder {
    /* Firefox 19+ */
    color: var(--color-light-grey);
    font-weight: 300;
  }

   :-ms-input-placeholder {
    /* IE 10+ */
    color: var(--color-light-grey);
    font-weight: 300;
  }

   :-moz-placeholder {
    /* Firefox 18- */
    color: var(--color-light-grey);
    font-weight: 300;
  }