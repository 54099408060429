/* CSS Variables */
:root {
  /* Colors */
  --color-primary: #e7995c;
  /* --color-primary: #F7E7CE; */
  --color-secondary: #f1d3d1;
  --color-black: #000;
  --color-white: #fff;
  --color-gold: #cda349;
  --color-gold-lighter: #d4af61;
  --color-light-grey: #ecf0f1;
  --color-red: #e74c3c;
  --color-green: #2ecc71;
  --color-grey: #bdc3c7;
  --text-color: #3b3b3b;
  /* Spacing */
  --spacing-tiny: 4px;
  --spacing-small: 8px;
  --spacing-medium: 16px;
  --spacing-large: 32px;
  --spacing-huge: 64px;
  --spacing-gigantic: 128px;
  --primary-font-family: "brandon-grotesque";
  --secondary-font-family: karmina;
  /* Font Sizing */
  --default-font-size: 16px;
}

/* Sass Variables */
/* Breakpoints */
/* Mixins */
/* Anchors */
a,
a:link,
a:active,
a:visited,
a:hover {
  text-decoration: none;
}

/* Buttons */
button {
  display: grid;
  place-items: center;
  padding: 0;
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
}

/* Forms */
input,
textarea,
select,
label {
  font-size: var(--default-font-size);
}

input.ng-dirty.ng-invalid {
  border-color: var(--color-red);
}

@media (max-width: 768px) {
  input[type=date].ng-pristine:before {
    content: "Please select a date";
    display: block;
    margin-right: var(--spacing-small);
  }
}

select {
  background-color: var(--color-white);
}

fieldset {
  border: none;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: var(--color-light-grey);
  /*font-weight: 300;*/
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: var(--color-light-grey);
  font-weight: 300;
}

:-ms-input-placeholder {
  /* IE 10+ */
  color: var(--color-light-grey);
  font-weight: 300;
}

:-moz-placeholder {
  /* Firefox 18- */
  color: var(--color-light-grey);
  font-weight: 300;
}

/* Generic */
* {
  box-sizing: border-box;
  font-family: var(--primary-font-family), sans-serif;
  color: var(--text-color);
}

html,
body {
  height: 100%;
}

body {
  font-size: var(--default-font-size);
}

::selection {
  background: var(--color-primary);
  /* WebKit/Blink Browsers */
}

::-moz-selection {
  background: var(--color-primary);
  /* Gecko Browsers */
}

/* Headings */
h1,
h2,
h3,
h4,
h5,
h6 {
  text-align: center;
  margin-top: 0;
  font-family: var(--secondary-font-family);
  font-weight: 200;
}

/* Images */
img {
  width: 100%;
}

/* Lists */
ul {
  list-style-type: none;
  padding-left: 0;
}

/* Paragraphs */
p {
  font-weight: 300;
  text-align: center;
  color: #484848;
}