/* CSS Variables */

:root {
  /* Colors */
  --color-primary: #e7995c;
  /* --color-primary: #F7E7CE; */
  --color-secondary: #f1d3d1;
  --color-black: #000;
  --color-white: #fff;
  --color-gold: #cda349;
  --color-gold-lighter: #d4af61;
  --color-light-grey: #ecf0f1;
  --color-red: #e74c3c;
  --color-green: #2ecc71;
  --color-grey: #bdc3c7;
  --text-color: #3b3b3b;

  /* Spacing */
  --spacing-tiny: 4px;
  --spacing-small: 8px;
  --spacing-medium: 16px;
  --spacing-large: 32px;
  --spacing-huge: 64px;
  --spacing-gigantic: 128px;

  --primary-font-family: "brandon-grotesque";
  --secondary-font-family: karmina;

  /* Font Sizing */
  --default-font-size: 16px;
}

/* Sass Variables */

/* Breakpoints */
$bp-xs: 320px;
$bp-sm: 480px;
$bp-md: 768px;
$bp-lg: 1024px;
$bp-xl: 1440px;
$bp-xxl: 2280px;
