/* Headings */

h1,
h2,
h3,
h4,
h5,
h6 {
  text-align: center;
  margin-top: 0;
  font-family: var(--secondary-font-family);
  font-weight: 200;
}
